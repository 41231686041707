<template>
  <div id="sideBar">
    <Dialog ref="dialog" :config="config" />
    <!-- 回到顶部 -->
    <div id="topBtn" class="sideBarGgroup">
      <div
        :class="isHover == 7 ? 'sideBarGgroupOpt top' : 'sideBarGgroupOpt'"
        @mouseenter="isHover = 7"
        @mouseleave="isHover = -1"
        @click="toTop"
        style="cursor: pointer"
      >
        <i class="el-icon-arrow-up"></i>
      </div>
    </div>
    <!-- 聊天列表 -->
    <div class="chat" v-if="false">
      <div class="raku" :class="{ hideChat: show }" @click="$fun.clickJs">
        <div class="cueNumber" v-if="unread">{{ unread }}</div>
        <img src="../../assets/index-img/raku1.svg" alt="" />
        <div class="title">
          {{ $fanyi("Raku消息") }}
        </div>
      </div>
      <div
        class="raku whatAPP"
        :class="{ hideChat: show }"
        @click="$fun.clickJs()"
      >
        <img src="../../assets/index-img/whatAPP1.svg" alt="" />
        <div class="title">WhatsApp</div>
      </div>
      <div class="btn" @click="show = !show">
        <div class="cueNumber" v-if="unread">{{ unread }}</div>
        <img
          src="../../assets/index-img/chat.svg"
          alt=""
          :class="{ rotateInco: !show }"
        />
        <i class="el-icon-close" :class="{ rotate: !show }"></i>
      </div>
    </div>
  </div>
</template>

<script>
import Dialog from "../../components/public/Dialog";
export default {
  data() {
    return {
      show: true,
      isHover: -1,
      unread: 0,
      config: {
        top: "20vh",
        width: "500px",
        title: this.$fanyi("是否登录"),
        btnTxt: [this.$fanyi("前往登录"), this.$fanyi("关闭")],
      },
    };
  },
  components: { Dialog },
  computed: {
    evn() {
      // || location.origin == 'http://localhost:8080'
      if (
        location.origin == "https://www.rakumart.es" ||
        location.origin == "https://rakumart.eu" ||
        location.origin == "www.rakumart.es" ||
        location.origin == "http://www.rakumart.es"
      ) {
        return true;
      } else {
        return true;
      }
    },
    chatType() {
      return this.$store.state.userInfo.chat;
    },
    chatLink() {
      return this.$store.state.userInfo
        ? this.$store.state.userInfo.whatsapp
        : "";
    },
  },
  created() {
    this.messageUnread();
  },

  methods: {
    messageUnread() {
      if (!this.$store.state.userInfo) {
        return;
      }
      this.$api
        .messageUnread({ uid: this.$store.state.userInfo.user_id })
        .then((res) => {
          //接下来的操作
          if (res.data.code) {
            this.unread = res.data.count;
          } else {
            this.unread = 0;
          }
        });
    },
    chat(chat) {
      if (this.$store.state.userInfo) {
        let iTop = (window.screen.availHeight - 30 - 700) / 2;
        //获得窗口的水平位置
        let iLeft = (window.screen.availWidth - 10 - 1260) / 2;
        if (chat == 1) {
          // 老rakuchat页面链接
          window.open(
            "https://rakuchat1.rakumart.cn/im/dist/#/?id=" +
              this.$store.state.userInfo.user_id,
            this.$store.state.userInfo,
            "height=700, width=1260, left=" +
              iLeft +
              ", top=" +
              iTop +
              ", toolbar=no, menubar=no, scrollbars=yes, resizable=no, location=no, status=no"
          );
        } else {
          window.open(
            this.chatLink ? this.chatLink : "HTTPS://wa.me/+34621188939",
            "WhatsApp",
            "height=700, width=1260, left=" +
              iLeft +
              ", top=" +
              iTop +
              ", toolbar=no, menubar=no, scrollbars=yes, resizable=yes, location=no, status=no"
          );
        }
      } else {
        this.$store.commit(
          "getshowLoginDialog",
          !this.$store.state.showLoginDialog
        );
      }
    },
    toTop() {
      let timer = setInterval(function () {
        let osTop =
          document.documentElement.scrollTop || document.body.scrollTop;
        let ispeed = Math.floor(-osTop / 60);
        document.documentElement.scrollTop = document.body.scrollTop =
          osTop + ispeed;
        this.isTop = true;
        if (osTop === 0) {
          clearInterval(timer);
        }
      }, 2);
    },
  },
};
</script>
<style lang="scss" scoped="scoped">
@import "../../css/mixin";
.chat {
  position: fixed;
  right: 30px;
  bottom: 120px;
  .cueNumber {
    position: absolute;
    background-color: red;
    $size: 20px;
    width: $size;
    height: $size;
    line-height: $size;
    text-align: center;
    color: white;
    border-radius: 50%;

    top: -5px;
    right: 0;
  }
  .btn {
    width: 50px;
    height: 50px;
    background-color: #1a299e;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    cursor: pointer;

    img {
      height: 30px;
      width: 30px;

      transform: rotate(0);
      transition: all 0.7s;
    }
    i {
      color: #fff;
      font-size: 18px;
      font-weight: bold;
      transition: all 0.7s;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      opacity: 0;
    }
    .rotate {
      transform: translate(-50%, -50%) rotate(90deg);
      opacity: 1;
    }
    .rotateInco {
      transform: rotate(-90deg);
      opacity: 0;
    }
  }
  .raku {
    position: absolute;
    top: -120px;
    left: 0;
    width: 50px;
    height: 50px;
    background-color: pink;
    border-radius: 50%;
    cursor: pointer;
    box-shadow: 0px 9px 8px 0px rgba(41, 41, 41, 0.11);
    transition: all 0.8s;
    opacity: 1;
    &:hover {
      transform: scale(1.1);
      transition: none;
      .title {
        display: block;
      }
    }
    img {
      width: 100%;
      height: 100%;
    }
    .title {
      position: absolute;
      top: 50%;
      left: -124px;
      transform: translate(0, -50%);
      font-size: 12px;
      background-color: #fff;
      box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
      width: 114px;
      height: 34px;
      text-align: center;
      line-height: 34px;
      border-radius: 5px;
      display: none;
      border-top: 1px solid #1a299e;
    }
  }
  .whatAPP {
    top: -60px;
  }
  .hideChat {
    top: 0;
    z-index: -1;
    opacity: 0;
  }
}
#sideBar {
  // option选项的大小
  $sideBarOptSize: 50px;
  width: $sideBarOptSize;
  position: fixed;
  right: 20px;

  bottom: 100px;
  z-index: 99;
  height: 50px;
  .sideBarGgroup {
    display: none;
    // 侧边栏的圆角角度

    margin-bottom: 50px;
    .sideBarGgroupOpt {
      font-weight: bold;

      &.top {
        color: $homePageSubjectColor;
      }
      .el-icon-arrow-up {
        font-size: 25px;
        font-weight: bold;
        margin-bottom: 5px;
        &:hover {
          color: $homePageSubjectColor;
        }
      }
      border-radius: 50%;
      background-color: white;
      display: flex;
      justify-content: center;
      align-items: center;
      width: $sideBarOptSize;
      height: $sideBarOptSize;
      $sideBarIconSize: 50px;
      box-shadow: 0px 9px 8px 0px rgba(41, 41, 41, 0.11);
      img {
        width: $sideBarIconSize;
        height: $sideBarIconSize;
      }
    }
  }
}
.chitchat {
  position: fixed;
  top: 50%;
  transform: translate(0, -50%);
  right: 0px;
  background: #ffffff;
  border: 1px solid #e3e3e3;
  border-radius: 3px;
  width: 133px;
  height: 48px;
  cursor: pointer;
  display: flex;
  align-items: center;
  padding: 14px 20px;
  .el-icon-arrow-up {
    margin: 0 2px 0 auto;
  }
  img {
    width: 20px;
    height: 20px;
    margin-right: 11px;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }
  span {
    font-size: 14px;
    font-weight: 400;
    color: #404040;
  }
}
/deep/.el-dialog__body {
  padding: 25px 60px 30px 30px !important;
  span {
    font-size: 16px !important;
  }
}
</style>
